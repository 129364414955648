.tabs {
	display: grid;
	grid-template-columns: 1fr 2fr;
	column-gap: 50px;
	@media #{media("medium-down")} {
		grid-template-columns: 1fr 1fr;
	}
	@media #{media("small-down")} {
		grid-template-columns: 1fr;
	}
}
.tab {
	grid-column: 1 / span 1;
}
.tab-heading {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	padding: 10px 0;
	border-bottom: 1px solid var(--color-foreground);
	cursor: pointer;
	opacity: 1;
	transition: .35s ease-out;
	&::marker,
	&::-webkit-details-marker {
		display: none;
		color: transparent;
		content:none;
	}
	&:after,
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		right: 0;
		width: 25px;
		height: 0;
		border-bottom: 2px solid var(--color-foreground);
		transition: .35s ease-out;
	}
	&:before {
		transform: rotate(-90deg);
	}
	.tab[open] > &:before {
		transform: rotate(0);
	}
	.tab:not([open]) > & {
		opacity: .5;
	}
	.tab:hover > & {
		opacity: 1;
	}
	> * {
		margin: 0;
	}
	svg {
		width: 32px;
		.container-wrapper--black & {
			fill: white;
		}
	}
}
.tab-content {
	opacity: 0;
	pointer-events: none;
	transform: translateY(-25px);
	transition: .35s ease-out;
	background: rgba(255,255,255,.25);
	padding: 25px;
	box-shadow: 0 0 6px rgba(255,255,255,0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	[open] + & {
		opacity: 1;
		transform: translateY(0);
		pointer-events: auto;
	}
	@media #{media("small-up")} {
		grid-column: 2 / span 2;
		grid-row: 1 / span var(--rows, 1);
	}
	@media #{media("small-down")} {
		height: 0;
		[open] + & {
			height: auto;
		}
	}
}
.container-bg .tab-background {
	opacity: 1;
	transform: scale(1.1);
	transition: opacity .35s ease-out, transform 6s ease-out;
	&[data-hidden] {
		opacity: 0;
		transform: scale(1);
	}
}