.nav {
	--c: 255,255,255;
	&.nav--main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 10;
		color: white;
	}
	&.nav--fixed {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 10;
		animation: fade-in-down .35s ease-out;
		background: rgba(0,0,0,.8);
		box-shadow: 0 0 6px rgba(0,0,0,.5);
	}
	&.nav--absolute {
		position: absolute;
		top: 0;
		left: 0;
	}
}
@keyframes fade-in-down {
	from {opacity: 0; transform: translateY(-100%)}
	to {opacity: 1; transform: translateY(0)}
}
.nav-wrapper {
	position: relative;
	display: flex;
	width: 100%;
	max-width: $containerWidth;
	padding: 25px 50px;
	margin: 0 auto;
	justify-content: flex-start;
	align-items: center;
	line-height: 20px;
	.nav--fixed & {
		backdrop-filter: blur(6px);
	}
	.nav--main & {
		height: $navHeight;
	}
	.nav--sub & {
		min-height: $navHeight;
	}
	@media #{map-get($mediaqueries, "medium-down")} {
		padding: 10px 25px;
		.nav--main & {
			height: $navHeightMedium;
		}
		.nav--sub & {
			min-height: 50px;
			padding: 25px;
		}
	}
	@media #{map-get($mediaqueries, "small-down")} {
		display: block;
		.nav--sub & {
			text-align: center;
			.nav-logo {
				margin: 0;
			}
		}
	}
	@media (max-width: $navBreakpoint) {
		.nav--opened & {
			backdrop-filter: none;
		}
		> * {
			z-index: 2;
		}
		&:before {
			content: "";
			position: absolute;
			top: -500px;
			left: 0;
			width: 100%;
			height: 0;
			transition: .35s ease-out;
			transform-origin: center top;
			background: rgba(0,0,0,.8);
			backdrop-filter: blur(10px);
			box-shadow: 0 0 40px rgba(black,.2);
		}
		.nav--opened &:before {
			top: 0;
			height: calc(100% + 100vh - $navHeightMedium);
			border-radius: 0;
		}
	}
}
.nav-logo, a.nav-logo {
	position: relative;
	z-index: 2;
	display: inline-block;
	height: 32px;
	margin-right: 25px;
	background: none;
	padding: 0;
	svg {
		height: 100%;
		width: auto;
		fill: black;
	}
	svg:last-child {
		display: none;
		animation: 2s scan-line linear infinite;
	}
	&.nav-logo--invert {
		svg {
			fill: white;
		}
	}
	&:hover {
		svg:first-child {
			display: none;
		}
		svg:last-child {
			display: inline-block;
		}
	}
}
.nav-icon {
	position: relative;
	z-index: 2;
	display: block;
	height: 32px;
	margin-right: 25px;
	padding: 0;
	svg {
		height: 100%;
		width: auto;
		fill: black;
	}
	svg:last-child {
		display: none;
		animation: 2s scan-line linear infinite;
	}
	&:last-child {
		margin-right: 0;
	}
	&.nav-icon--invert {
		svg {
			fill: white;
		}
	}
	&:hover {
		svg:first-child {
			display: none;
		}
		svg:last-child {
			display: inline-block;
		}
	}
}
@keyframes logo-x {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	50% {
		transform: translateX(-50px);
		opacity: 0;
	}
	50.1% {
		transform: translateX(50px);
		opacity: 0;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}
.nav-items {
	list-style-type: none;
	margin: 0;
	padding: 0;
	@media (max-width: $navBreakpoint) {
		position: absolute;
		top: $navHeightMedium;
		left: 0;
		width: 100%;
		overflow-y: auto;
		overscroll-behavior: contain;
		display: flex;
		flex-direction: column;
		align-items: center;
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		transition: opacity .35s ease-in-out;
		.nav--opened & {
			height: calc(100vh - $navHeightMedium);
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}
		.nav--sub & {
			position: static;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			text-align: center;
			visibility: visible;
			opacity: 1;
			pointer-events: auto;
		}
	}
	@media #{map-get($mediaqueries, "small-down")} {
		.nav--sub & {
			display: block;
		}
	}
	.nav-item {
		position: relative;
		display: inline-block;
		margin: 0 10px;
		@media (max-width: $navBreakpoint) {
			margin: 10px 0;
		}
		> a {
			@extend .subtitle;
			@extend .link;
			position: relative;
			display: inline-block;
			padding: 10px;
			background:
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/0 1px,
				linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 0/100% 200%,
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/0 1px;
			&:hover {
				background:
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 0/100% 1px,
				linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 100%/100% 200%,
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px;
			}
			.nav--sub & {
				--c: 0,0,0;
			}
		}
		> a:not([href]) {
			cursor: default;
			background: none;
		}
		@media (max-width: $navBreakpoint) {
			text-align: center;
			& > a.active {
				background:
					linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 0/100% 1px,
					linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 100%/100% 200%,
					linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px;
			}
			> a:not([href]) {
				cursor: pointer;
			}
		}
	}
	.nav-cta {
		display: none;
		position: absolute;
		top: 25px;
		right: 50px;
		margin: 0;
		.nav--fixed:not(.nav--nocta) & {
			display: block;
		}
		> a {
			margin: 0;
		}
		@media #{map-get($mediaqueries, "medium-down")} {
			top: 5px;
			right: 25px;
		}
		@media (max-width: $navBreakpoint) {
			display: block;
			position: static;
			margin: 10px 0 25px;
		}
	}
}
.nav-subitems {
	display: inline-block;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 40px;
	left: 0;
	min-width: 100%;
	z-index: 9;
	list-style-type: none;
	margin: 0;
	padding: 10px 25px;
	transition: background-position .3s 0s ease-out, opacity .6s 0s, background-size .3s .3s;
	background:
		linear-gradient(white, white) no-repeat 0 0/0 1px,
		linear-gradient(rgba(0,0,0,0.8) 50%, transparent 0) no-repeat 0 100%/100% 200%;
	@media (min-width: #{$navBreakpoint + 1px}) {
		li:hover > & {
			opacity: 1;
			pointer-events: auto;
			transition: background-position .3s .3s ease-out, opacity .6s 0s ease-out, background-size .3s 0s;
			background:
				linear-gradient(white, white) no-repeat 0 0/100% 1px,
				linear-gradient(rgba(0, 0, 0, 0.8) 50%, transparent 0) no-repeat 0 0/100% 200%;
		}
	}
	@media (max-width: $navBreakpoint) {
		position: relative;
		top: 0;
		display: none;
		overflow: hidden;
		transform: none;
		visibility: visible;
	    pointer-events: auto;
		transition: opacity .35s ease-in-out;
		&:before {
			box-shadow: inset 0 -3px 6px rgba(255,255,255,.5);
			top: 0;
			height: 100%;
			.inbounz & {
				background: color("corporate");
			}
			.outbounz & {
				background: black;
			}
		}
	    .active + & {
	    	opacity: 1;
	    }
	}
	.nav-subitem {
		display: block;
		transition: .25s ease-out;
		margin: .75em 0;
		> a {
			@extend .link;
			display: block;
			padding: 10px 0;
			font-weight: normal;
			transition: .3s ease-out;
			background: linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/25px 1px;
			@media (max-width: $navBreakpoint) {
				background: linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 50% 100%/25px 1px;
			}
			&:hover {
				background: linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px;
			}
		}
	}
}
.nav-languages-wrapper {
	display: block;
	position: absolute;
	top: 30px;
	right: 50px;
	.nav--fixed:not(.nav--nocta) & {
		display: none;
	}
	@media #{map-get($mediaqueries, "medium-down")} {
		top: 10px;
		right: 25px;
	}
	@media (max-width: $navBreakpoint) {
		display: none;
		.nav--opened & {
			display: block;
			position: static;
			margin: 10px 0 25px;
		}
	}
}
.nav-languages {
	list-style-type: none;
	margin: 0;
	padding: 0;
	text-align: center;
	li {
			position: relative;
			display: inline-block;
			margin: 0;
		> a {
			@extend .subtitle;
			@extend .link;
			text-transform: uppercase;
			display: inline-block;
			height: 40px;
			width: 40px;
			padding: 10px 0;
			background:
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/0 1px,
				linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 0/100% 200%,
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/0 1px;
		}
		&.active > a {
			background:
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/0 1px,
				linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 0/100% 200%,
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px;
		}
		&:hover > a {
			background:
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 0/100% 1px,
				linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 100%/100% 200%,
				linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px;
		}
		&.active:hover > a {
			transition: .3s linear;
		}
	}
}
.nav-burger, a.nav-burger {
	display: none;
	position: absolute;
	top: 5px;
	right: 17px;
	height: 50px;
	width: 50px;
	cursor: pointer;
	overflow: hidden;
	background: none;
	@media (max-width: $navBreakpoint) {
		display: block;
	}
	span {
		position: absolute;
		right: 10px;
		width: 25px;
		height: 5px;
		transition: .2s ease-in-out;
		background: rgb(var(--c));
		&:before {
			content: "";
			position: absolute;
			top: 0;
			width: 5px;
			height: 5px;
			transition: .2s ease-in-out;
			background: rgb(var(--c));
		}
		&:nth-child(1) {
			top: 10px;
			right: 8px;
			&:before {
				top: 5px;
				right: 0;
			}
		}
		&:nth-child(2) {
			top: 23px;
			right: 18px;
			&:before {
				display: none;
			}
		}
		&:nth-child(3) {
			top: 36px;
			right: 8px;
			&:before {
				top: -5px;
				right: 0;
			}
		}
	}
	&:hover {
		span:nth-child(1) {
			right: 18px;
			&:before {
				right: calc(100% - 5px);
			}
		}
		span:nth-child(2) {
			right: 8px;
		}
		span:nth-child(3) {
			right: 18px;
			&:before {
				right: calc(100% - 5px);
			}
		}
	}
	.nav--opened & {
		span:nth-child(1) {
			top: 23px;
			right: 11px;
			width: 28px;
			transform: rotate(45deg);
			transform-origin: center center;
			&:before {
				height: 0;
			}
		}
		span:nth-child(2) {
			width: 4px;
			right: calc(50% - 2px);
		}
		span:nth-child(3) {
			top: 23px;
			right: 11px;
			width: 28px;
			transform: rotate(-45deg);
			transform-origin: center center;
			&:before {
				height: 0;
			}
		}
	}
}
.nav-info {
	position: absolute;
	right: 50px;
	line-height: 50px;
	@media #{map-get($mediaqueries, "medium-down")} {
		right: 25px;
	}
	@media #{map-get($mediaqueries, "small-down")} {
		position: static;
	}
}