.button, a.button {
	position: relative;
	z-index: 2;
	@extend .subtitle;
	display: inline-block;
	padding: 0;
	margin: 1.5rem 0;
	color: inherit;
	background: none;
	> span {
		display: inline-block;
		padding: 10px 35px;
		border: 6px solid currentColor;
		vertical-align: middle;
		clip-path: polygon(0 0, 100% 0, 100% calc(50% - 3px), calc(100% - 10px) calc(50% - 3px), calc(100% - 10px) calc(50% + 3px), 100% calc(50% + 3px), 100% 100%, 0 100%, 0 calc(50% + 3px), 10px calc(50% + 3px), 10px calc(50% - 3px), 0 calc(50% - 3px));
	}
	&.button--disabled {
		opacity: .5;
	}
	&:not(.button--disabled):hover {
		background: none;
		> span {
			animation: 2s scan-line linear infinite;
			background: none;
		}
	}
}
@keyframes scan-line {
	0% {
		clip-path: polygon(0 0, 100% 0, 100% calc(50% - 3px), 50% calc(50% - 3px), 50% calc(50% + 3px), 100% calc(50% + 3px), 100% 100%, 0 100%, 0 calc(50% + 3px), 50% calc(50% + 3px), 50% calc(50% - 3px), 0 calc(50% - 3px));
	}
	25% {
		clip-path: polygon(0 0, 100% 0, 100% calc(5% - 3px), 50% calc(5% - 3px), 50% calc(5% + 3px), 100% calc(5% + 3px), 100% 100%, 0 100%, 0 calc(5% + 3px), 50% calc(5% + 3px), 50% calc(5% - 3px), 0 calc(5% - 3px));
	}
	75% {
		clip-path: polygon(0 0, 100% 0, 100% calc(95% - 3px), 50% calc(95% - 3px), 50% calc(95% + 3px), 100% calc(95% + 3px), 100% 100%, 0 100%, 0 calc(95% + 3px), 50% calc(95% + 3px), 50% calc(95% - 3px), 0 calc(95% - 3px));
	}
	100% {
		clip-path: polygon(0 0, 100% 0, 100% calc(50% - 3px), 50% calc(50% - 3px), 50% calc(50% + 3px), 100% calc(50% + 3px), 100% 100%, 0 100%, 0 calc(50% + 3px), 50% calc(50% + 3px), 50% calc(50% - 3px), 0 calc(50% - 3px));
	}
}
@keyframes scan-line-overlay {
	0% {
		top: calc(50% - 3px);
	}
	25% {
		top: calc(5% - 3px);
		clip-path: polygon(0 0, 100% 0, 100% calc(5% - 3px), 50% calc(5% - 3px), 50% calc(5% + 3px), 100% calc(5% + 3px), 100% 100%, 0 100%, 0 calc(5% + 3px), 50% calc(5% + 3px), 50% calc(5% - 3px), 0 calc(5% - 3px));
	}
	75% {
		top: calc(95% - 3px);
	}
	100% {
		top: calc(50% - 3px);
	}
}
