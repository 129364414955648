// ALIGN
.u-center {
	text-align: center;
}
.u-left {
	text-align: left;
}
.u-right {
	text-align: right;
}
.u-justify {
	text-align: justify;
	@media #{map-get($mediaqueries, "medium-down")} {
		text-align: left;
	}
}

// MARGINS PADDINGS
.u-nomargin {
	margin: 0 !important;
}
.u-nomargin-top {
	margin-top: 0 !important;
}
.u-nomargin-bottom {
	margin-bottom: 0 !important;
}
.u-nomargin-left {
	margin-left: 0 !important;
}
.u-nomargin-right {
	margin-right: 0 !important;
}
.u-nopadding {
	padding: 0 !important;
}
.u-nopadding-top {
	padding-top: 0 !important;
}
.u-nopadding-bottom {
	padding-bottom: 0 !important;
}
.u-nopadding-left {
	padding-left: 0 !important;
}
.u-nopadding-right {
	padding-right: 0 !important;
}
.u-padding-top {
	padding-top: 50px;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding-top: 25px;
	}
}
.u-bigpadding-top {
	padding-top: 100px;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding-top: 50px;
	}
}
.u-padding-bottom {
	padding-bottom: 50px;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding-bottom: 25px;
	}
}
.u-bigpadding-bottom {
	padding-bottom: 100px;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding-bottom: 75px;
	}
}

// COLORS

@each $color_name, $color_value in $colors {

	.u-color-#{$color_name} {
		color: $color_value;
	}

	.u-bgcolor-#{$color_name} {
		background-color: $color_value;
	}

}