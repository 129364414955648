.doc {
	position: relative;
	width: 100%;
	max-width: 1500px;
	padding: $navHeight 50px 0 calc(33.33333% + 50px);
	margin: 0 auto;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding: $navHeightMedium 25px 0 calc(33.33333% + 25px);
	}
	@media #{map-get($mediaqueries, "small-down")} {
		padding: $navHeightMedium 25px 0;
	}
	@media #{map-get($mediaqueries, "xlarge-up")} {
		padding: $navHeight 50px 0 650px;
    }
	figure {
		margin: 50px 0;
		text-align: center;
		@media #{map-get($mediaqueries, "medium-down")} {
			margin: 25px 0;
		}
	}
	img {
		display: inline-block;
		width: auto;
		max-width: 100%;
		max-height: calc(80vh - $navHeight);
		box-shadow: 0 0 3px rgba(0,0,0,.5);
		@media #{map-get($mediaqueries, "medium-down")} {
			max-height: calc(80vh - $navHeightMedium);
		}
	}
	pre {
		width: 100%;
		overflow-x: auto;
		margin: 1em 0;
		padding: 8px;
		background: color("black");
	    white-space: break-spaces;
	}
	code {
		width: 100%;
	    padding: 1px 4px;
	    border-radius: 3px;
	    font-family: monospace;
	    font-size: 16px;
		background: color("black");
		color: color("white");
	}
	.table-wrapper {
		width: 100%;
		margin: 1em 0;
		overflow-x: auto;
	}
	table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid color("grey");
	}
	th, td {
		padding: 8px;
		line-height: 22px;
		border: 1px solid color("grey");
	}
	th {
		text-align: left;
		font-size: 0.875rem;
	    font-weight: normal;
	    text-transform: uppercase;
	    letter-spacing: 2px;
		background: color("black");
		color: color("white");
	}
	blockquote {
		margin: 1em 0;
		padding: 25px;
		background: color("black");
		color: color("white");
	}
	h4 {
		margin-top: 2em;
	}
	h4 + blockquote {
		margin-top: 0;
	}
	.nav-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}
.doc-content {
	padding-top: $navHeight;
	@media #{map-get($mediaqueries, "medium-down")} {
		padding-top: $navHeightMedium;
	}
	ul, ol {
		list-style-type: none;
		padding-left: 2em;
		counter-reset: list-counter;
	}
	li {
		counter-increment: list-counter;
	}
	ul > li:before,
	ol > li:before {
		position: absolute;
		color: color("corporate");
		font-weight: bold;
		display: inline-block;
		margin-left: -1em;
	}
	ul > li:before {
		content: "\2022";
		font-size: 1.25em;
		line-height: 0.8em;
	}
	ol > li:before {
		content: counter(list-counter) ".";
		font-size: 1em;
		line-height: inherit;
	}
	a {
		@extend .link;
	}
}
.video {
	position: relative;
	width: 100%;
	max-width: calc((80vh - $navHeight) / 0.5625);
	margin: 0 auto;
	background: rgba(0,0,0,.07);
	border: 10px solid color("corporate");
	.container-wrapper--color & {
		background: rgba(255,255,255,.5);
		.container-wrapper--black & {
			border-color: color("white");
		}
	}
	@media #{map-get($mediaqueries, "medium-down")} {
		max-width: calc((80vh - $navHeightMedium) / 0.5625);
	}
}
.video-wrapper {
	position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    pointer-events: none;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: all;
    }
}
.video-consent {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	text-align: center;
	color: color("black");
	> div {
		margin: auto;
		max-height: 100%;
		overflow-y: auto;
		padding: 50px;
		@media #{map-get($mediaqueries, "small-down")} {
			padding: 25px;
		}
	}
}
.doc-nav {
    position: fixed;
    top: $navHeight;
    left: 0;
    width: 33%;
    z-index: 9;
    height: calc(100% - $navHeight);
    padding: 25px;
    overflow-y: auto;
    transition: .35s ease-out;
	background-color: color("black");
	color: color("white");
	@media #{map-get($mediaqueries, "medium-down")} {
		top: $navHeightMedium;
		height: calc(100% - $navHeightMedium);
    }
	@media #{map-get($mediaqueries, "xlarge-up")} {
        padding: 50px;
        width: 600px;
    }
	@media #{map-get($mediaqueries, "small-down")} {
		top: 60px;
        width: calc(100% - 60px);
		height: calc(100% - 60px);
        transform: translateX(-100%);
        padding-bottom: 80px;
        &.doc-nav--opened {
            transform: translateX(0);
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin: 0;
            padding: 5px 0;
            a {
	            display: inline-block;
                text-decoration: none;
                font-weight: normal;
				border: 0;
	            color: color("white");
	            transition: .35s ease-out;
				span {
					color: color("darkgrey");
					font-weight: bold;
				}
                &:hover {
                    transform: translateX(5px);
                }
            }
        }
        ul {
            padding-left: 25px;
        }
    }
	> ul > li > a {
		text-transform: uppercase;
		margin-top: 15px;
	}
    > div > ul > li {
        padding-bottom: 15px;
        &:last-child {
            padding-bottom: 0;
        }
    }
}
.doc-nav-icon {
    position: fixed;
    bottom: 20px;
    left: -10px;
    z-index: 9;
    padding: 10px 20px 10px 30px;
    background-color: color("corporate");
    transition: .35s ease-out;
    cursor: pointer;
    color: color("black");
	box-shadow: 0 0 40px rgba(0,0,0,.2);
	font-size: 0.875rem;
    font-weight: normal;
    font-style: italic;
    text-transform: uppercase;
    letter-spacing: 2px;
	transform: skewX(-14deg);
    > * {
    	display: inline-block;
    	transform: skewX(14deg);
    }
	@media #{map-get($mediaqueries, "small-up")} {
        display: none;
    }
    .material-icons:nth-child(1) {
        display: none;
    }
    .doc-nav-close {
    	display: none;
    	margin-right: 10px;
    }
    .doc-nav--opened + & {
        background-color: color("black");
        color: color("corporate");
        border: 1px solid color("corporate");
        .doc-nav-close {
        	display: inline-block;
        }
    }
}