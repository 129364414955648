h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Barlow Condensed', sans-serif;
    margin-bottom: 0.75em;
    margin-top: 0.75em;
    text-transform: uppercase;
    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }
    &.banner {
        color: #fff;
        background: #000;
        margin-bottom: 10px;
        padding: 7px 10px 3px;
        display: inline-block;
        .container-wrapper--black & {
            color: #000;
            background: #fff;
        }
    }
    h4 + & {
        margin-top: 0;
    }
}
h5,
h6 {
    text-transform: none;
}

h1,
.h1 {
    font-size: 2.375rem;
    font-weight: bold;
    line-height: 1.2;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(2.375rem + 0.025 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 3.75rem;
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

h2,
.h2 {
    font-size: 1.875rem;
    font-weight: bold;
    line-height: 1.2;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(1.875rem + 0.015 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 2.75rem
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

h3,
.h3 {
    font-size: 1.35rem;
    font-weight: bold;
    line-height: 1.2;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(1.35rem + 0.01 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 1.75rem
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

h4,
.h4 {
    @extend .subtitle;
    margin: 0;
}

h5,
.h5 {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.2;
}

.subtitle {
    font-family: 'Fira Mono', monospace;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
}

.intro {
    font-size: 1.125rem;
    margin: 0 0 .75rem;

    @media #{map-get($mediaqueries, "medium-up")} {
        font-size: calc(1.125rem + 0.0025 * (100vw - 1020px));
    }

    @media #{map-get($mediaqueries, "xlarge-up")} {
        font-size: 1.25rem
    }

    @media #{map-get($mediaqueries, "small-down")} {
        hyphens: auto;
    }
}

.intro-link {
    font-size: 1rem;
    white-space: nowrap;

    i {
        margin: 0 15px;

        @media #{map-get($mediaqueries, "small-down")} {
            margin-left: 0;
        }
    }

    @media #{map-get($mediaqueries, "small-down")} {
        display: block;
    }
}