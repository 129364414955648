/* barlow-condensed-800 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 800;
    src: url('https://cloudworx.agency/assets/images/emailfooter/reedr/barlow-condensed-v12-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: url('https://cloudworx.agency/assets/images/emailfooter/reedr/barlow-v12-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 400;
    src: url('https://cloudworx.agency/assets/images/emailfooter/reedr/barlow-v12-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 700;
    src: url('https://cloudworx.agency/assets/images/emailfooter/reedr/barlow-v12-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* barlow-700italic - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Barlow';
    font-style: italic;
    font-weight: 700;
    src: url('https://cloudworx.agency/assets/images/emailfooter/reedr/barlow-v12-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* fira-mono-700 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Fira Mono';
    font-style: normal;
    font-weight: 700;
    src: url('https://cloudworx.agency/assets/images/emailfooter/reedr/fira-mono-v14-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}