.blog {
    @include container(800px);
}
.blog-teasers {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
}
.blog-teaser {
    position: relative;
    width: 300px;
}
.blog-teaser-text {
    padding-top: 15px;
}
.blog-teaser-img {
    position: relative;
    padding: 11px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 6px solid color("black");
        clip-path: polygon(0 0, 13px 0, 13px 7px, calc(100% - 13px) 7px, calc(100% - 13px) 0, 100% 0, 100% 13px, calc(100% - 7px) 13px, calc(100% - 7px) calc(100% - 13px), 100%  calc(100% - 13px), 100% 100%, calc(100% - 13px) 100%, calc(100% - 13px) calc(100% - 7px), 13px calc(100% - 7px), 13px 100%, 0 100%, 0 calc(100% - 13px), 7px calc(100% - 13px), 7px 13px, 0 13px, 0 0);
    }
}