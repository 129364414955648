.logothumbnails {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 50px;
	padding-top: 50px;
	@media #{map-get($mediaqueries, "medium-down")} {
		gap: 25px;
		padding-top: 25px;
	}
}
.logothumbnails-logo {
	max-width: 150px;
	max-height: 80px;
	object-fit: contain;
	object-position: center;
	filter: grayscale(1);
	transition: .35s ease-out;
	@media #{map-get($mediaqueries, "medium-down")} {
		max-width: 100px;
		max-height: 40px;
	}
	&:hover {
		filter: grayscale(0);
	}
}