html {
    font-size: 1rem;
    font-family: 'Barlow', sans-serif;
    line-height: 1.4;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

body {
    position: relative;
    overflow-x: hidden;
    margin: 0;
    scroll-behavior: smooth;
    --c: 0,0,0;
}

main {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

p a,
.link {
    --s: 0s;
    font-weight: 700;
    color: inherit;
    padding: .25em;
    text-decoration: none;
    background:
        linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 0/100% 1px,
        linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 100%/100% 200%,
        linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    transition: .3s var(--s) ease-out,background-size .3s calc(.3s - var(--s));
    &:hover {
        background:
                linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/100% 1px,
                linear-gradient(transparent 50%, rgba(var(--c),0.25) 0) no-repeat 0 0/100% 200%,
                linear-gradient(rgba(var(--c),1), rgba(var(--c),1)) no-repeat 0 100%/0 1px;
        --s: .3s;
    }
}
h1,h2,h3,h4,h5,h6 {
    a, a:hover {
        background: none;
    }
}

p {
    margin: 0.75rem 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

ul,
ol,
li>ol,
li>ul {
    margin-bottom: 0.75rem;
}

ul,
ol {
    :not(li)>&:last-child {
        margin-bottom: 0;
    }
}

li {
    margin: 0 0 .75rem;
}

hr {
    display: block;
    margin: 0;
    width: 100%;
    height: 4px;
    border: none;
    border-bottom: 1px solid color("black");
    outline: none;
    .container-wrapper--black & {
        border-color: color("white");
    }
}

img,
svg {
    width: 100%;
    max-width: 100%;
}

.portrait {
    position: relative;
    width: 120px;
    display: inline-block;
    padding: 11px;
    margin: 25px 0;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 6px solid color("black");
        clip-path: polygon(0 0, 13px 0, 13px 7px, calc(100% - 13px) 7px, calc(100% - 13px) 0, 100% 0, 100% 13px, calc(100% - 7px) 13px, calc(100% - 7px) calc(100% - 13px), 100%  calc(100% - 13px), 100% 100%, calc(100% - 13px) 100%, calc(100% - 13px) calc(100% - 7px), 13px calc(100% - 7px), 13px 100%, 0 100%, 0 calc(100% - 13px), 7px calc(100% - 13px), 7px 13px, 0 13px, 0 0);
    }
    img {
        position: relative;
        border-radius: 50%;
        background: color("grey");
        filter: grayscale(1);
        box-shadow: 0 0 6px rgba(0,0,0,.5);
        .container-wrapper--grey & {
            background: color("white");
        }
        .container-wrapper--black & {
            box-shadow: 0 0 6px rgba(255,255,255,.5);
        }
    }
    .container-wrapper--black & {
        border-color: color("white");
    }
}

figure {
    position: relative;
    padding: 0;
    margin: 1.5rem 0;

    &.can-fullscreen {
        cursor: pointer;
    }

    &.can-fullscreen.is-fullscreen {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        background: rgba(0, 0, 0, .8);
        z-index: 999;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .figure-close {
            display: block;
        }

        img {
            width: 100%;
            height: auto;
            max-width: 100%;
            max-height: 100vh;
            object-fit: contain
        }
    }

    .figure-close {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(255, 255, 255, .5);
        padding: 5px;
        font-style: italic;
        font-weight: bold;
        line-height: 30px;
        width: 40px;
        font-size: 20px;
        text-align: center;
        cursor: pointer;
        color: map-get($colors, "darkgrey");
    }
}

figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: rgba(255, 255, 255, .5);
    padding: 5px;
    font-style: italic;
    color: map-get($colors, "darkgrey");
}

* {

    &,
    &:before,
    &:after {
        box-sizing: inherit;
    }

}

.text-icon {
    width: 1em;
    height: 1em;
    display: inline-flex;
    background: color("black");
    border-radius: 50%;
    padding: 0.25em;
    align-items: center;
    justify-content: center;
    color: color("white");
    margin-right: 0.125em;
    transform: translateY(0.125em);
    svg {
        height: 1em;
    }
}

.container-wrapper {
    position: relative;
    min-height: calc(100vh - 100px);
    --c: 0,0,0;
    color: black;

    @media #{map-get($mediaqueries, "medium-down")} {
        min-height: calc(100vh - 50px);
    }

    &.container-wrapper--slim {
        min-height: auto;
    }

    &.container-wrapper--black {
        --c: 255,255,255;
        color: white;
    }

    &.container-wrapper--clipped {
        overflow: hidden;
    }

    &.container-wrapper--fullheight {
        min-height: 100vh;
        display: flex;
    }
}

.container-wrapper--color,
.container-wrapper--black,
.container-wrapper--grey {
    position: relative;
}

@mixin container($max-width: none, $withSidePadding: true) {
    position: relative;
    width: 100%;
    margin: 0 auto;
    max-width: $max-width;

    @if ($withSidePadding) {
        padding: #{$navHeight + 50px} 50px 50px;

        @media #{map-get($mediaqueries, "medium-down")} {
            padding: #{$navHeightMedium + 25px} 25px 25px;
        }
    }

    @else {
        padding: #{$navHeight + 50px} 0 50px;

        @media #{map-get($mediaqueries, "medium-down")} {
            padding: #{$navHeightMedium + 25px} 0 25px;
        }
    }
}

.container {
    @include container($containerWidth);
    min-height: 100vh;
    display: flex;
    align-items: center;

    .container-wrapper--black &,
    .container-wrapper--color & {
        color: color("black");

        a:not(.button) {
            color: color("black");
            border-color: color("white");
        }

        h4 {
            color: color("white");
        }
    }

    .container-wrapper--color &,
    .container-wrapper--black & {
        color: color("white");

        a:not(.button) {
            color: color("white");
        }
    }

    .container-wrapper--slim & {
        min-height: auto;
        justify-content: center;
    }

    &.container--slim {
        padding: 50px;

        @media #{map-get($mediaqueries, "medium-down")} {
            padding: 25px;
        }
    }

    &.container--fullwidth {
        max-width: none;
    }

    & > * {
        width: 100%;
    }
}

.container-bgcolor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    pointer-events: none;
    clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
    background-color: color("white");

    .container-wrapper--black & {
        background-color: color("black");
    }

    .container-wrapper--grey & {
        background-color: color("grey");
    }
}

.container-bgcolor-img {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    mix-blend-mode: soft-light;
}

.container-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    perspective: 2000px;

    > div {
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center center;
        filter: grayscale(100%);
        opacity: .15;
        .container-wrapper--black & {
            opacity: .25;
        }
    }

    > img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        opacity: .15;
        .container-wrapper--black & {
            opacity: .25;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: -50%;
        left: -50%;
        right: -50%;
        bottom: -50%;
        width: 200%;
        height: 200%;
        background: transparent url('/assets/images/noise-transparent.png') repeat 0 0;
        animation: bg-animation .2s infinite;
        visibility: visible;
        opacity: .5;
        .container-wrapper--black & {
            opacity: 1;
        }
    }

    @keyframes bg-animation {
        0% { transform: translate(0,0) }
        10% { transform: translate(-5%,-5%) }
        20% { transform: translate(-10%,5%) }
        30% { transform: translate(5%,-10%) }
        40% { transform: translate(-5%,15%) }
        50% { transform: translate(-10%,5%) }
        60% { transform: translate(15%,0) }
        70% { transform: translate(0,10%) }
        80% { transform: translate(-15%,0) }
        90% { transform: translate(10%,5%) }
        100% { transform: translate(5%,0) }
    }
}

.container-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 25px;
    > .button {
        margin: 0;
    }
}

.container-video {
    position: absolute;
    top: 100px;
    left: 50%;
    width: 50%;
    height: calc(100% - 100px);
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-left: 50px;

    @media #{map-get($mediaqueries, "medium-down")} {
        top: 50px;
        height: calc(100% - 50px);
        padding-left: 25px;
    }

    @media #{map-get($mediaqueries, "small-down")} {
        position: static;
        width: 100%;
        height: auto;
        padding-left: 0;
    }
}

.container-heading {
    width: 50%;

    .container-wrapper--center & {
        width: 100%;
        text-align: center;
        max-width: 750px;
        margin: 0 auto;
    }

    @media #{map-get($mediaqueries, "small-down")} {
        width: 100%;
    }
}

.container-imgtext {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    max-width: 875px;

    > :first-child {
        width: 25%;
        padding-right: 25px;
        padding-left: 0;
    }

    > :last-child {
        width: 75%;
    }

    &.container-imgtext--inverted {
        text-align: right;
        justify-content: flex-end;

        > :first-child {
            order: 2;
            padding-right: 0;
            padding-left: 25px;
        }
    }

    @media #{map-get($mediaqueries, "small-down")} {

	    > :first-child {
	        width: 35%;
	    }

	    > :last-child {
	        width: 65%;
	    }
	}

    @media #{map-get($mediaqueries, "xsmall-down")} {
        flex-direction: column;
        align-items: flex-start;

	    > :first-child {
	        width: 100%;
	        padding-right: 25px;
	        padding-left: 25px;
	        padding-bottom: 25px;
	        text-align: center;
	        img {
	        	display: inline-block;
	        	max-width: 175px;
	        }
	    }

	    > :last-child {
	        width: 100%;
	        text-align: center;
	    }

        &.container-imgtext--inverted {
            align-items: flex-end;

            > :first-child {
                order: 0;
            }
        }
    }
}

.container-img {
    width: auto;
    max-height: 50vh;

    @media #{map-get($mediaqueries, "medium-down")} {
        margin-bottom: 50px;
    }
}

.container-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, 50%);
    .icon-bottomleft,
    .icon-bottomright {
        fill: white;
    }
    .icon-topleft,
    .icon-topright {
        fill: black;
    }
    .container-wrapper--black & {
        .icon-bottomleft,
        .icon-bottomright {
            fill: black;
        }
        .icon-topleft,
        .icon-topright {
            fill: white;
        }
    }
}

.container-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 25px;

    .container-wrapper--black &,
    .container-wrapper--color & {
        color: color("black");
    }

    .container-wrapper--color &,
    .container-wrapper--black & {
        color: color("white");
    }

    .container-wrapper--center & {
        text-align: center;
    }

    +.container {
        @media #{map-get($mediaqueries, "small-down")} {
            padding-bottom: 50px;
        }
    }
}

.container-badge {
    position: absolute;
    bottom: 50px;
    right: 50px;
    z-index: 5;
    width: 220px;
    max-width: calc(100% - 100px);
    border: 0;
    background: none;
    img {
        display: block;
    }

    @media #{map-get($mediaqueries, "medium-down")} {
        bottom: 25px;
        right: 25px;
    }
}

.container-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    margin: 25px 0;
    width: 100%;
    > * {
        width: 100%;
        max-width: 300px;
        text-align: center;
        img {
            filter: grayscale(1);
        }
    }
}

.parallax {
    // overflow: hidden;
}